body {
  background: -webkit-gradient(linear, left bottom, left top, from(#bfcfe8), to(#fff));
  background: linear-gradient(0deg, #bfcfe8, #fff);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  font-family: 'Lora', serif, Arial, sans-serif;
  /* font-family: Inter, Roboto, 'Helvetica Neue', 'Arial Nova',
	       'Nimbus Sans', Arial, sans-serif;
    background-color: lightblue;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

h2, p {
    font-family: 'Lora', serif;
}

p {
    font-style: italic;
}

input, select, button {
    font-family: 'Open Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
